import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "@/components/design-system/alert";
import { Badge } from "@/components/design-system/badge";
import { Button } from "@/components/design-system/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
} from "@/components/design-system/dialog";
import {
  GridTable,
  GridTableCell,
  GridTableRow,
} from "@/components/design-system/grid";
import { Heading } from "@/components/design-system/heading";
import { Select } from "@/components/design-system/select";
import { Loading } from "@/components/Loading";
import AgentIcon from "@/components/styled/AgentIcon";
import Logo from "@/components/styled/Logo";
import { useAgents } from "@/contexts/AgentsContext";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, AgentTemplate } from "@/utils/types";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

export default function NewAgentView() {
  const [justClicked, setJustClicked] = useState<string | null>(null);
  const {
    createNewAgent,
    deleteAgent,
    cloneAgentFromTemplate,
    newConversation,
  } = useAgents();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [agentToBeDeleted, setAgentToBeDeleted] = useState<Agent | null>(null);
  const [agentTemplates, setAgentTemplates] = useState<AgentTemplate[]>([]);
  const location = useLocation();
  const [isOnboarding, setIsOnboarding] = useState(
    location.search.includes("welcome=true"),
  );
  const [loadingTemplates, setLoadingTemplates] = useState(true);

  const handleTemplateClick = (template: AgentTemplate) => {
    setJustClicked(template.uuid);
    cloneAgentFromTemplate(template);
  };

  useEffect(() => {
    const loadTemplates = async () => {
      const templates = await MarconipyApi.agent.getAgentTemplates();
      setAgentTemplates(templates);
      // Extract unique categories
      const uniqueCategories = [
        "All",
        ...new Set(templates.map((template) => template.category)),
      ];
      setCategories(uniqueCategories);
      setLoadingTemplates(false);
    };
    loadTemplates();
  }, []);

  useEffect(() => {
    if (isOnboarding && window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-11340473661/ZpkSCMH-7okZEL3Kx58q",
      });
    }
  }, [isOnboarding]);

  const handleCloseOnboarding = async () => {
    await MarconipyApi.user.update({
      onboarded: true,
    });
    setIsOnboarding(false);
    window.history.replaceState({}, "", "/agents/new");
  };
  const localcreateNewAgent = async () => {
    setJustClicked("blank");
    createNewAgent();
  };
  const [selectedCategory, setSelectedCategory] = useState<string>("All");
  const [categories, setCategories] = useState<string[]>([]);
  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedCategory(event.target.value);
  };
  let filteredTemplates =
    selectedCategory === "All"
      ? agentTemplates.filter(
          (template) => !template.name.toLowerCase().includes("hide"),
        )
      : agentTemplates.filter(
          (template) =>
            template.category === selectedCategory &&
            !template.name.toLowerCase().includes("hide"),
        );
  filteredTemplates = filteredTemplates.sort((a, b) => {
    // First sort by category
    const categoryComparison = a.category.localeCompare(b.category);
    // If categories are the same, sort by name
    if (categoryComparison === 0) {
      return a.name.localeCompare(b.name);
    }
    return categoryComparison;
  });
  let featuredTemplates = filteredTemplates.filter(
    (template) => template.featured,
  );

  const renderCard = ({
    uuid,
    icon,
    title,
    description,
    category,
    featured,
    onClick,
    alwaysShowDescription,
  }: {
    uuid: string;
    icon: React.ReactNode;
    title: string;
    description: string;
    category: string;
    featured: boolean;
    onClick: () => void;
    alwaysShowDescription?: boolean;
  }) => {
    return (
      <div
        className={clsx(
          `relative flex flex-col max-w-[270px] h-[200px] rounded-xl`,
          `border border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900`,
          `group hover:cursor-pointer transition-all duration-200`,
          {
            "animate-pulse": justClicked === uuid,
          },
        )}
        onClick={onClick}
      >
        <div className="text-base mb-2 flex gap-3 items-start pt-4 px-4">
          {icon && (
            <div className="transition-opacity">
              <AgentIcon icon={icon} size="md" showBorder={false} />
            </div>
          )}
          <p className="text-lg">{title}</p>
        </div>
        <div
          className={clsx(
            "flex-col gap-2 pl-4 pb-4 mb-2 pr-2 text-black h-20 group-hover:h-full text-sm scrollbar-thin dark:text-white opacity-70 group-hover:opacity-100 group-hover:overflow-y-auto",
            {
              "overflow-y-hidden whitespace-break-spaces text-ellipsis":
                !alwaysShowDescription,
            },
          )}
        >
          <p>{description}</p>
        </div>
        <p className="bg-white absolute bottom-2 right-4 opacity-80 text-xs uppercase tracking-wide group-hover:hidden dark:bg-gray-900">
          {featured && <Badge className="mr-2">Featured</Badge>}
          <Badge>{category}</Badge>
        </p>
      </div>
    );
  };

  if (loadingTemplates) {
    return <Loading />;
  }

  return (
    <div className="h-full">
      <Helmet>
        <title>New Agent - TailorTask</title>
      </Helmet>
      <div className="flex flex-col p-8">
        {/* {agents.length > 0 && (
          <>
            <div className="flex gap-4 items-center">
              <Heading className="min-w-[80%]">Chat with your agents</Heading>
            </div>
            <div>
              <GridTable className="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-0">
                {agents
                  .sort((agent: Agent) => {
                    return agent.active ? -1 : 1;
                  })
                  .map((agent: Agent) => (
                    <GridTableRow
                      key={agent.uuid}
                      className="cursor-pointer"
                      onClick={() => handleAgentClick(agent)}
                    >
                      <GridTableCell>
                        <Tooltip content={agent.name}>
                          <div
                            className={`max-w-[270px] flex gap-2 p-2 truncate border rounded-xl py-4 border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900 items-center ${justClicked == agent.uuid ? "animate-pulse" : ""}`}
                          >
                            <AgentIcon
                              icon={
                                agent.icon != ""
                                  ? agent.icon
                                  : agent.name.split("")[0]
                              }
                              showActiveIndicator
                              isActive={agent.active}
                            />
                            <h3 className="text-sm text-ellipsis overflow-hidden whitespace-nowrap">
                              {justClicked == agent.uuid
                                ? "Loading..."
                                : agent.name}
                            </h3>
                          </div>
                        </Tooltip>
                      </GridTableCell>
                    </GridTableRow>
                  ))}
              </GridTable>
            </div>
            <Divider />
          </>
        )} */}
        <div className="flex gap-4 items-center justify-between">
          <Heading className="">Start a new agent</Heading>
          <div
            className={`flex min-w-[20%] ${selectedCategory == "All" ? "opacity-70" : "opacity-100"}`}
          >
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              className="text-primary"
            >
              {/* <option value="All">All</option> */}
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div>
          {selectedCategory == "All" && (
            <>
              <h2 className="uppercase text-xs mt-4 opacity-80">Featured</h2>
              <GridTable className="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 pt-0">
                <GridTableRow>
                  <GridTableCell>
                    {renderCard({
                      uuid: "new",
                      onClick: localcreateNewAgent,
                      icon: "+",
                      title: "Blank agent",
                      description:
                        "Create your own Agent from scratch in less than 10 minutes.",
                      category: "Any use case",
                      featured: false,
                      alwaysShowDescription: true,
                    })}
                  </GridTableCell>
                </GridTableRow>
                {loadingTemplates && <Loading />}
                {featuredTemplates.map((template: AgentTemplate) => (
                  <GridTableRow key={template.uuid}>
                    <GridTableCell>
                      {renderCard({
                        uuid: template.uuid,
                        onClick: () => handleTemplateClick(template),
                        icon: template.agent.icon || template.agent.name[0],
                        title: template.name,
                        description: template.description,
                        category: template.category,
                        featured: true,
                      })}
                    </GridTableCell>
                  </GridTableRow>
                ))}
              </GridTable>
            </>
          )}
          {selectedCategory == "All" && (
            <h2 className="uppercase text-xs opacity-80">All templates</h2>
          )}

          <GridTable className="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4">
            {filteredTemplates.map((template: AgentTemplate) => (
              <GridTableRow key={template.uuid}>
                <GridTableCell>
                  {renderCard({
                    uuid: template.uuid,
                    onClick: () => handleTemplateClick(template),
                    icon: template.agent.icon || template.agent.name[0],
                    title: template.name,
                    description: template.description,
                    category: template.category,
                    featured: template.featured,
                  })}
                </GridTableCell>
              </GridTableRow>
            ))}
          </GridTable>
          {selectedCategory != "All" && (
            <>
              <div className="flex items-center mt-8 justify-center">
                <p className="text-xs">
                  Filtering by: {selectedCategory}.{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setSelectedCategory("All");
                    }}
                    className="underline hover:text-primary hover:no-underline"
                  >
                    Show all templates
                  </a>
                </p>
              </div>
            </>
          )}
        </div>
        <Alert
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <AlertTitle>Confirm Agent Deletion</AlertTitle>
          <AlertDescription>
            Are you sure you want to delete this agent? This action cannot be
            undone.
          </AlertDescription>
          <AlertActions>
            <Button
              variant="secondary"
              onClick={() => {
                setAgentToBeDeleted(null);
                setIsDeleteModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setIsDeleting(true);
                agentToBeDeleted && deleteAgent(agentToBeDeleted);
              }}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting Agent..." : "Delete agent"}
            </Button>
          </AlertActions>
        </Alert>
        <Dialog open={isOnboarding} onClose={handleCloseOnboarding} size="xl">
          <Logo className="h-8 mb-4" />
          <DialogBody className="flex flex-col gap-2 dark:text-white">
            <Heading>Welcome to TailorTask: AI Automation Beyond Chat</Heading>
            <p>Automate repetitive tasks with intelligent AI agents:</p>
            <ol className="list-decimal ml-5">
              <li>Brief Your Agent: Describe your task and goals</li>
              <li>Review & Refine: Approve the agent or provide feedback</li>
              <li>Execute & Control: Run on-demand or schedule</li>
            </ol>
            <Heading className="mt-4">What makes TailorTask unique?</Heading>
            <ul className="list-disc ml-5">
              <li>AI agents that do tasks, not just chat</li>
              <li>
                Performs real actions like web searches and content creation
              </li>
              <li>Learns and adapts to your needs</li>
            </ul>
            <p>
              To create your first agent, start from scratch or use a template
            </p>
          </DialogBody>
          <DialogActions>
            <Button onClick={handleCloseOnboarding}>Start automating</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  return {};
}
