import AgentApi from "./api/agents";
import ApiTokenApi from "./api/apiToken";
import AuthApi from "./api/auth";
import BaseApi from "./api/base";
import BillingApi from "./api/billing";
import ConversationApi from "./api/conversations";
import GoogleAdsApi from "./api/googleAds";
import GoogleDriveApi from "./api/googleDrive";
import InputApi from "./api/inputs";
import NotificationApi from "./api/notifications";
import OauthApi from "./api/oauth";
import OutputChannelApi from "./api/outputChannels";
import OutputTemplateApi from "./api/outputTemplates";
import PublicApi from "./api/public";
import RunApi from "./api/runs";
import SlackApi from "./api/slack";
import SourceApi from "./api/sources";
import TeamApi from "./api/teams";
import TriggerApi from "./api/triggers";
import UserApi from "./api/user";

class MarconipyApi extends BaseApi {
  static agent = AgentApi;
  static auth = AuthApi;
  static billing = BillingApi;
  static conversation = ConversationApi;
  static googleDrive = GoogleDriveApi;
  static googleAds = GoogleAdsApi;
  static input = InputApi;
  static notification = NotificationApi;
  static oauth = OauthApi;
  static outputChannel = OutputChannelApi;
  static outputTemplate = OutputTemplateApi;
  static public = PublicApi;
  static run = RunApi;
  static slack = SlackApi;
  static source = SourceApi;
  static team = TeamApi;
  static trigger = TriggerApi;
  static user = UserApi;
  static apiToken = ApiTokenApi;
}

export default MarconipyApi;
