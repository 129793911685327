// import { Task, Message } from "@/utils/types";
import Tooltip from "@/components/styled/Tooltip";
import HelpPanel from "@/screens/partials/HelpPanel";
import { RightSidePanelOptions } from "@/utils/types";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import React from "react";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCheckLine,
  RiCloseLine,
  RiEqualizerFill,
  RiInformation2Line,
  RiQuestionLine,
  RiSettingsLine,
} from "react-icons/ri";
import { Navbar, NavbarItem, NavbarSpacer } from "./design-system/navbar";
import { StackedLayout } from "./design-system/stacked-layout";

type Props = {
  agentUUID: string;
  sidePanelActiveTab: string;
  activeStep: string | null;
  toggleStep: (id: string, automatic?: boolean) => void;
  steps: any[];
  switchSidePanelTab: (tab: RightSidePanelOptions) => void;
};

const RightSidePanel = ({
  sidePanelActiveTab,
  activeStep,
  toggleStep,
  steps,
  switchSidePanelTab,
}: Props) => {
  return (
    <React.Fragment>
      <div
        className={`bg-white flex inset-y-0 right-0 transform transition-all duration-300 ease-in-out overflow-hidden ring-1 ring-zinc-950/5 dark:bg-zinc-900 dark:ring-white/10 ${
          sidePanelActiveTab === "none"
            ? "translate-x-full w-0 hidden"
            : "translate-x-0 w-1/2 lg:w-1/3"
        }`}
      >
        <StackedLayout
          navbar={
            <Navbar className="h-[61px]">
              <NavbarItem
                aria-label="Set up"
                onClick={() => switchSidePanelTab("settings")}
                current={sidePanelActiveTab == "settings"}
              >
                <RiEqualizerFill />
                Set up
              </NavbarItem>
              <Tooltip content="Help">
                <NavbarItem
                  aria-label="Help"
                  onClick={() => switchSidePanelTab("help")}
                  current={sidePanelActiveTab == "help"}
                >
                  <RiQuestionLine />
                  Help
                </NavbarItem>
              </Tooltip>
              <NavbarSpacer />
              <Tooltip content="Close sidebar">
                <NavbarItem
                  aria-label="Close sidebar"
                  onClick={() => switchSidePanelTab("none")}
                >
                  <RiCloseLine />
                </NavbarItem>
              </Tooltip>
            </Navbar>
          }
          sidebar={null}
        >
          <div className="overflow-y-hidden h-[calc(100vh-76px)] w-full">
            <div className="w-full p-8 bg-primary-light/10 flex gap-2">
              <RiInformation2Line className="w-6 h-6 shrink-0" />
              {activeStep == null &&
                `Chat with your Agent, and it will set up all the necessary
              information for you.`}
              {activeStep == "brief" &&
                `The Brief is a set of instructions that the Agent will follow to perform your workflows.`}
              {activeStep == "tools" &&
                `The Agent can use Actions to complete your workflow. Select all the Actions that apply to your workflow.`}
              {activeStep == "inputs" &&
                `You can specify Inputs that will be given to your Agent before running any workflow. Useful to give ever changing sets of instructions.`}
              {activeStep == "advanced" &&
                `You can schedule your Agent to run at a specific time, or on a recurring schedule.`}
            </div>
            <Transition
              show={sidePanelActiveTab == "settings"}
              enter="transition-all duration-100 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-all duration-100 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="divide-y divide-gray-100 color dark:divide-black-light h-full flex flex-col">
                {steps.map((step, index) => (
                  <div
                    key={step.id}
                    className={`ring-1 ring-zinc-950/5 dark:ring-white/10 flex flex-col`}
                  >
                    <div className="h-14">
                      <Tooltip
                        content={step.tooltip}
                        key={step.id}
                        placement="left"
                      >
                        <button
                          className={clsx(
                            "h-full",
                            activeStep == step.id
                              ? "bg-white dark:bg-black-dark w-full px-4 py-3 flex items-center justify-between text-left focus:outline-none hover:bg-gray-100 hover:dark:bg-black-light"
                              : "w-full px-4 py-3 flex items-center justify-between text-left focus:outline-none hover:bg-gray-100 hover:dark:bg-black-light",
                          )}
                          onClick={() => toggleStep(step.id)}
                        >
                          <div className="flex items-center">
                            <div
                              className={`w-8 h-8 rounded-full flex shrink-0 items-center justify-center mr-3 ${
                                step.isCompleted
                                  ? "bg-green text-white"
                                  : "bg-gray-100 text-black dark:bg-black-dark dark:text-white"
                              }`}
                            >
                              {step.isCompleted ? (
                                <RiCheckLine className="w-5 h-5" />
                              ) : (
                                step.icon
                              )}
                            </div>
                            <span className="text-sm font-medium text-black dark:text-white">{`${index + 1}. ${step.title}`}</span>
                          </div>
                          {activeStep === step.id ? (
                            <RiArrowUpSLine className="w-5 h-5 text-black" />
                          ) : (
                            <RiArrowDownSLine className="w-5 h-5 text-black" />
                          )}
                        </button>
                      </Tooltip>
                    </div>
                    <Transition
                      show={activeStep === step.id}
                      enter="transition-all duration-300 ease-out"
                      enterFrom="opacity-0 max-h-0"
                      enterTo="opacity-100 max-h-screen"
                      leave="transition-all duration-200 ease-in"
                      leaveFrom="opacity-100 max-h-screen"
                      leaveTo="opacity-0 max-h-0"
                    >
                      <div
                        className={`px-4 py-3 overflow-hidden h-[calc(100vh-480px)]`}
                      >
                        {step.content}
                      </div>
                    </Transition>
                  </div>
                ))}
              </div>
            </Transition>
            <Transition
              show={sidePanelActiveTab == "help"}
              enter="transition-all duration-100 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-all duration-100 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="p-4">
                <HelpPanel />
              </div>
            </Transition>
          </div>
        </StackedLayout>
      </div>
      {sidePanelActiveTab === "none" && (
        <div className="absolute right-0 top-0">
          <button
            onClick={() => switchSidePanelTab("settings")}
            className="h-[60px] px-5 border-l"
          >
            <Tooltip content="Open Sidebar">
              <RiSettingsLine />
            </Tooltip>
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default RightSidePanel;
