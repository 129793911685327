import { Button } from "@/components/design-system/button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from "@/components/design-system/dialog";
import { Divider } from "@/components/design-system/divider";
import { Description, Label } from "@/components/design-system/fieldset";
import { Switch, SwitchField } from "@/components/design-system/switch";
import Container from "@/components/styled/Container";
import CopyEmailButton from "@/components/styled/CopyEmailButton";
import { useTeams } from "@/contexts/TeamContext";
import MarconipyApi from "@/utils/marconipyApi";
import { show } from "@intercom/messenger-js-sdk";
import { loadStripe } from "@stripe/stripe-js";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { RiCheckFill } from "react-icons/ri";
import { redirect } from "react-router-dom";
import { UpgradeFAQSection } from "./partials/UpgradeFAQSection";

type PricingPlan = {
  name: string;
  type: string;
  price: string;
  price_year: string;
  features: string[];
  feature_without_icon?: string;
  cta: string;
};

const pricingPlans: PricingPlan[] = [
  {
    name: "Free",
    type: "free",
    price: "$0/month",
    price_year: "$0/month",
    features: ["Plan and execute tasks", "5 runs"],
    cta: "Get Started",
  },
  {
    name: "Professional",
    type: "professional",
    price: "$29/month",
    price_year: "$290/year",
    features: ["25 runs per month", "Email support"],
    feature_without_icon: "Everything in Free, plus",
    cta: "Upgrade Now",
  },
  {
    name: "Enterprise",
    type: "enterprise",
    price: "Custom",
    price_year: "Custom",
    features: [
      "Private cloud options",
      "Data segregation",
      "SSO integration",
      "Advanced roles and permissions",
    ],
    feature_without_icon: "Everything in Professional, plus",
    cta: "Contact Sales",
  },
];

export default function UpgradePlanView() {
  const posthog = usePostHog();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isYearly, setIsYearly] = useState(false);
  const { selectedTeam, plan, organization } = useTeams();

  const isOnFree = plan && plan.type == "free";
  const isOnProfessional = plan && plan.type == "professional";
  const isOnEnterprise = plan && plan.type == "enterprise";

  const handleUpgrade = async (planName: string) => {
    posthog.capture("upgradePlan button clicked", {
      from_type: plan && plan.type,
      to_type: planName,
    });
    if (planName == "enterprise") {
      posthog.capture("upgradePlan enterprise opened", {
        from_type: plan && plan.type,
        to_type: planName,
      });
      setShowModal(true);
      return;
    }
    if (plan && planName === plan.type) {
      if (planName == "free") {
        openCustomerPortal();
      }
    }
    if (planName == "professional" && isOnFree) {
      setLoading(true);
      openPaymentLink();
    }
    if (planName == "free" && isOnProfessional) {
      setLoading(true);
      openCustomerPortal();
    }
  };

  const isButtonDisabled = (planName: string) => {
    if (loading) return true;
    if (isOnEnterprise) {
      if (planName != "enterprise") {
        return true;
      }
    } else if (isOnProfessional) {
      if (planName == "free") {
        return true;
      }
    } else if (isOnFree) {
      if (planName == "free") {
        return true;
      }
    }
    return false;
  };

  const openPaymentLink = async () => {
    if (!organization) {
      return;
    }
    setLoading(true);
    const stripePromise = loadStripe(
      import.meta.env.VITE_REACT_APP_STRIPE_PUBLISHABLE_KEY ??
        "pk_test_51MiN3dBqgnIjFU4jrfdbvu82Y9SEDf5dDdAtze9sjKRDzabIcyeM2QEva9q5iiUFSI05OT0TURUmxStp0wizPVI900rUWSfzns",
    ) as any;
    posthog.capture("upgradePlan stripe professional opened", {
      from_type: plan && plan.type,
      to_type: "professional",
      billing: isYearly ? "professional_yearly" : "professional_monthly",
    });
    //open a link in a new tab
    let response =
      await MarconipyApi.billing.getStripePaymentLinkForOrganization(
        organization.uuid,
        isYearly,
      );
    const stripe = await stripePromise;
    setLoading(false);
    await stripe!.redirectToCheckout({
      sessionId: response.sessionId,
    });
  };

  const openCustomerPortal = async () => {
    if (!organization || !plan) {
      return;
    }
    setLoading(true);
    posthog.capture("upgradePlan stripe portal opened", {
      type: plan.type,
      billing: isYearly ? "professional_yearly" : "professional_monthly",
    });
    let response = await MarconipyApi.billing.getStripeCustomerPortalSession(
      organization.uuid,
    );
    window.location.href = response["url"];
  };

  const handleCloseFeedbackModal = () => {
    setShowModal(false);
  };

  const toggleBillingPeriod = () => {
    if (!plan) return;
    posthog.capture("upgradePlan billing period toggled", {
      from_type: plan.type,
      to_type: plan.type,
      billing: isYearly ? "yearly" : "monthly",
    });
    setIsYearly(!isYearly);
  };

  const getButtonText = (planType: string) => {
    if (!plan) return "Select Plan";
    if (planType === plan.type) {
      if (planType === "free") {
        return "Current Plan";
      } else {
        return "Current Plan";
      }
    }
    if (planType === "free" && plan.type === "professional") return "Downgrade";
    return (
      pricingPlans.find((plan) => plan.type === planType)?.cta || "Select Plan"
    );
  };

  return (
    <>
      <Helmet>
        <title>Choose your plan - TailorTask</title>
      </Helmet>
      <Container>
        <h1 className="text-3xl font-bold text-center mt-4 mb-4">
          {selectedTeam && selectedTeam.name}
          {selectedTeam && selectedTeam.name ? " - " : ""}Choose Your Plan
        </h1>
        <div className="flex justify-center mb-4 items-center">
          {/* <Toggle
            isActive={isYearly}
            handleToggle={toggleBillingPeriod}
            label={isYearly ? "Yearly Billing" : "Monthly Billing"}
            size="sm"
          /> */}
          <SwitchField>
            <Label>{isYearly ? "Yearly Billing" : "Monthly Billing"}</Label>
            <Description>
              {isYearly ? "Save up to 16%" : "Go Yearly to save up to 16%"}
            </Description>
            <Switch
              name="billing_yearly"
              checked={isYearly}
              onChange={() => toggleBillingPeriod()}
            />
          </SwitchField>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {pricingPlans.map((planLoop, index) => (
            <div
              key={index}
              className="border rounded-lg p-6 flex flex-col justify-between bg-white dark:bg-black"
            >
              <div>
                <h2 className="text-2xl font-semibold mb-4">{planLoop.name}</h2>
                <p className="text-3xl font-bold mb-6">
                  {isYearly ? planLoop.price_year : planLoop.price}
                </p>
                <ul className="space-y-2 mb-6">
                  {planLoop.feature_without_icon && (
                    <li className="flex items-center">
                      <span>{planLoop.feature_without_icon}</span>
                    </li>
                  )}
                  {planLoop.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center">
                      <RiCheckFill className="text-green-500 mr-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <Button
                onClick={() => handleUpgrade(planLoop.type)}
                disabled={isButtonDisabled(planLoop.type)}
                variant={index === 1 ? "primary" : "secondary"}
              >
                {getButtonText(planLoop.type)}
              </Button>
              {planLoop.type == "professional" &&
                plan?.type == "professional" && (
                  <p className="text-sm text-center mt-4">
                    <a
                      onClick={openCustomerPortal}
                      className="text-primary hover:underline cursor-pointer"
                    >
                      Manage your subscription
                    </a>
                  </p>
                )}
              {planLoop.type == "enterprise" && plan?.type == "enterprise" && (
                <p className="text-sm text-center mt-4">
                  Get in touch with your account manager at{" "}
                  <a
                    href="mailto:info@tailortask.ai"
                    className="text-primary hover:underline cursor-pointer"
                  >
                    info@tailortask.ai
                  </a>
                </p>
              )}
            </div>
          ))}
        </div>
        <br />
        <UpgradeFAQSection />
        <Divider className="my-8" />
        <h2 className="text-2xl font-semibold mb-8 text-center">
          Still have questions? Reach out
        </h2>
        <div className="max-w-3xl mx-auto space-y-4 text-center">
          <Button onClick={show} variant="primary">
            Contact us
          </Button>
        </div>
        <>
          <Dialog open={showModal} onClose={handleCloseFeedbackModal}>
            <DialogTitle>Reach out to our Account Managers</DialogTitle>
            <DialogDescription>
              Drop an email to{" "}
              <a
                className="underline font-bold text-primary"
                href="mailto:info@tailortask.ai"
              >
                info@tailortask.ai
              </a>{" "}
              and our account managers will get in touch with you.
            </DialogDescription>
            <DialogActions>
              <Button onClick={handleCloseFeedbackModal} variant="plain">
                Close
              </Button>
              <CopyEmailButton email="info@tailortask.ai" variant="primary" />
            </DialogActions>
          </Dialog>
        </>
      </Container>
    </>
  );
}

//this loader has team_id in the url params
export async function loader({ params }: { params: any }) {
  //check if the user is already logged in
  const loggedIn = MarconipyApi.user.isAuthenticated();
  if (!loggedIn) {
    console.log("User is not logged in");
    return redirect("/");
  }
  if (!params.team_id) {
    console.log("Team id not provided");
    return redirect("/team");
  }
  return {};
}

//this loader has team_id in the url params
export async function redirectPlanLoader({ params }: { params: any }) {
  //check if the user is already logged in
  const loggedIn = MarconipyApi.user.isAuthenticated();
  if (!loggedIn) {
    console.log("User is not logged in");
    return redirect("/");
  }
  const default_team = await MarconipyApi.team.default();
  return redirect(`/teams/${default_team.team.uuid}/upgrade`);
}
