import { AgentNavigationBar } from "@/components/AgentNavigationBar";
import { Button } from "@/components/design-system/button";
import { Subheading } from "@/components/design-system/heading";
import { Loading } from "@/components/Loading";
import TimeLabel from "@/components/styled/TimeLabel";
import { useAgents } from "@/contexts/AgentsContext";
import { Agent, Conversation } from "@/utils/types";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { RiAddLine } from "react-icons/ri";
import { useNavigate } from "react-router";

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

export default function ConversationsByAgent() {
  const [justClicked, setJustClicked] = useState<string | null>(null);
  const { conversationsByAgent, agent, newConversation } = useAgents();
  const navigate = useNavigate();

  const handleConversationClick = (conversation: Conversation) => {
    if (!agent) {
      return;
    }
    setJustClicked(conversation.uuid);
    navigate("/agents/" + agent.uuid + "/chats/" + conversation.uuid);
  };

  const handleNewConversation = async (agent: Agent) => {
    setJustClicked("new-chat");
    await newConversation(agent);
  };

  if (!agent) {
    return <Loading />;
  }

  return (
    <div className="h-full">
      <Helmet>
        <title>{agent.name} - TailorTask</title>
      </Helmet>
      <AgentNavigationBar
        agent={agent}
        activeSidePanelTab={"settings"}
        switchSidePanelTab={() => {}}
        newAnimatedName={agent.name}
      >
        <div className="flex flex-col p-8 gap-4 h-full overflow-y-auto">
          {conversationsByAgent.length > 0 && (
            <>
              <div className="flex justify-between items-center">
                <Subheading>Your last chats</Subheading>
                <div>
                  <Button
                    onClick={() => handleNewConversation(agent)}
                    variant="primary"
                    disabled={justClicked == "new-chat"}
                  >
                    <RiAddLine className="mr-2" />
                    New Chat
                  </Button>
                </div>
              </div>
              <div className="space-y-3">
                {conversationsByAgent
                  .sort(
                    (a: Conversation, b: Conversation) =>
                      new Date(b.updated_at).getTime() -
                      new Date(a.updated_at).getTime(),
                  )
                  .map((conversation: Conversation) => (
                    <div
                      key={conversation.uuid}
                      onClick={() => handleConversationClick(conversation)}
                      className="cursor-pointer p-4 rounded-lg border border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900 transition-colors"
                    >
                      <div className="flex justify-between items-start gap-4">
                        <p className="text-sm text-gray-900 dark:text-gray-100 line-clamp-2">
                          {justClicked == conversation.uuid
                            ? "Loading..."
                            : (conversation.last_message_text ??
                              "Result is ready.")}
                        </p>
                        <span className="text-xs text-gray-500 dark:text-gray-400 whitespace-nowrap">
                          <TimeLabel time={conversation.updated_at} />
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </AgentNavigationBar>
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  return {};
}
