import { Button } from "@/components/design-system/button";
import { Heading } from "@/components/design-system/heading";
import CopyEmailButton from "@/components/styled/CopyEmailButton";
import { show } from "@intercom/messenger-js-sdk";
import { RiBook2Fill, RiMessage2Fill, RiYoutubeFill } from "react-icons/ri";

const HelpPanel = () => {
  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      <Heading className="mb-8 text-2xl font-bold text-gray-900 dark:text-white">
        Need Help?
      </Heading>

      <div className="flex flex-col gap-8 my-4">
        <div className="flex items-start p-4 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200">
          <RiBook2Fill className="w-8 h-8 mr-6 text-tertiary-dark flex-shrink-0" />
          <div>
            <h3 className="text-xl font-semibold mb-2">Documentation</h3>
            <p className="text-gray-600 dark:text-gray-300 mb-3">
              Find detailed guides and tutorials
            </p>
            <a
              href="https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=73"
              target="_blank"
              className="inline-flex items-center text-primary-dark dark:text-primary-light hover:underline font-medium"
              rel="noreferrer"
            >
              Visit Documentation
              <span className="ml-2">→</span>
            </a>
          </div>
        </div>

        <div className="flex items-start p-4 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200">
          <RiYoutubeFill className="w-8 h-8 mr-6 text-primary flex-shrink-0" />
          <div>
            <h3 className="text-xl font-semibold mb-2">Video Tutorials</h3>
            <p className="text-gray-600 dark:text-gray-300 mb-3">
              Watch step-by-step video guides
            </p>
            <a
              href="https://www.youtube.com/channel/UCXw-jB1hgaP99Q5PiIszMPg"
              target="_blank"
              className="inline-flex items-center text-primary-dark dark:text-primary-light hover:underline font-medium"
              rel="noreferrer"
            >
              Visit YouTube Channel
              <span className="ml-2">→</span>
            </a>
          </div>
        </div>

        <div className="flex items-start p-4 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200">
          <RiMessage2Fill className="w-8 h-8 mr-6 text-secondary-dark flex-shrink-0" />
          <div>
            <h3 className="text-xl font-semibold mb-2">Contact Support</h3>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              Get help from our support team
            </p>
            <div className="flex gap-4">
              <Button
                onClick={() => show()}
                variant="secondary"
                className="flex items-center gap-2 px-6 shrink-0"
              >
                <RiMessage2Fill />
                Ask in chat
              </Button>
              <CopyEmailButton email="info@tailortask.ai" variant="secondary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPanel;
