import { Agent } from "@/utils/types";

import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import React, { useEffect, useState } from "react";
import { RiAddBoxFill, RiDeleteBin2Fill } from "react-icons/ri";
import { Input } from "./design-system/input";
import { Textarea } from "./design-system/textarea";

type QuestionAnswer = {
  id: string;
  question: string;
  answer: string;
};

type ContextEditorProps = {
  agent: Agent;
};

const ContextEditor = ({ agent }: ContextEditorProps) => {
  const [saving, setSaving] = useState(false);
  const [justSaved, setJustSaved] = useState(false);
  const [editingField, setEditingField] = useState<{
    id: string;
    field: string;
  } | null>(null);

  // Add handlers for editing
  const startEditing = (id: string, field: string) => {
    setEditingField({ id, field });
  };

  const stopEditing = () => {
    setEditingField(null);
  };

  useEffect(() => {
    let qapairs = [
      {
        question: "Loading",
        answer: "Loading",
      },
    ];
    if (agent.context?.qa_pairs) {
      qapairs = agent.context.qa_pairs;
    }
    let newquestionanswers = [];
    for (let i = 0; i < qapairs.length; i++) {
      newquestionanswers.push({
        id: i.toString(),
        question: qapairs[i].question,
        answer: qapairs[i].answer,
      });
    }
    setQuestionAnswers(newquestionanswers);
  }, [agent.context]);

  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswer[]>([
    { id: "1", question: "What is your product?", answer: "" },
    { id: "2", question: "Who is your target audience?", answer: "" },
  ]);

  const [error, setError] = useState<string | null>(null);

  const addQuestionAnswer = () => {
    const newQuestionAnswer = {
      id: Date.now().toString(),
      question: "",
      answer: "",
    };
    setQuestionAnswers([...questionAnswers, newQuestionAnswer]);
    setEditingField({ id: newQuestionAnswer.id, field: "question" });
  };

  const updateQuestionAnswer = (
    id: string,
    field: "question" | "answer",
    value: string,
  ) => {
    setQuestionAnswers(
      questionAnswers.map((qa) =>
        qa.id === id ? { ...qa, [field]: value } : qa,
      ),
    );
  };

  const removeQuestionAnswer = (id: string) => {
    setQuestionAnswers(questionAnswers.filter((qa) => qa.id !== id));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = questionAnswers.every(
      (qa) => qa.question.length >= 5 && qa.answer.length >= 5,
    );

    if (isValid) {
      setError(null);
      onSubmit(questionAnswers);
    } else {
      setError("Each question and answer must be at least 5 characters long.");
    }
  };

  const onSubmit = async (questionAnswers: QuestionAnswer[]) => {
    setSaving(true);
    const context = {
      qa_pairs: questionAnswers.map((qa) => ({
        question: qa.question,
        answer: qa.answer,
      })),
    };

    try {
      await MarconipyApi.agent.updateAgentContext(agent.uuid, context);
      setSaving(false);
      setJustSaved(true);
      setTimeout(() => setJustSaved(false), 2000);
    } catch (e) {
      setError("Failed to update context");
    }
  };

  const renderQAField = (
    qa: QuestionAnswer,
    field: "question" | "answer",
    placeholder: string,
  ) => {
    const isEditing =
      editingField?.id === qa.id && editingField?.field === field;

    if (isEditing) {
      return field === "question" ? (
        <Input
          value={qa[field]}
          onChange={(e) => updateQuestionAnswer(qa.id, field, e.target.value)}
          placeholder={placeholder}
          autoFocus
          onBlur={stopEditing}
          onKeyDown={(e) => e.key === "Enter" && stopEditing()}
        />
      ) : (
        <Textarea
          value={qa[field]}
          onChange={(e) => updateQuestionAnswer(qa.id, field, e.target.value)}
          placeholder={placeholder}
          autoFocus
          onBlur={stopEditing}
          className="w-full dark:text-white"
        />
      );
    }
    return (
      <div
        onClick={() => startEditing(qa.id, field)}
        className="p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 cursor-text min-h-[2.5rem]"
      >
        {qa[field] || <span className="text-gray-400">{placeholder}</span>}
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8 mb-6">
      <div className="rounded-lg">
        {questionAnswers.map((qa) => (
          <div key={qa.id} className="space-y-3 mb-6 pl-4 border-l-2">
            <div className="flex justify-between gap-4 items-center font-semibold">
              {renderQAField(qa, "question", "What would you like to know?")}
              <Button
                type="button"
                variant="plain"
                onClick={() => removeQuestionAnswer(qa.id)}
                className="hover:text-red-500"
              >
                <RiDeleteBin2Fill />
              </Button>
            </div>
            {renderQAField(qa, "answer", "Provide a detailed answer")}
          </div>
        ))}
        <Button
          type="button"
          onClick={addQuestionAnswer}
          variant="secondary"
          className="w-full"
        >
          <RiAddBoxFill className="mr-2" /> Add New Question & Answer
        </Button>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
          {error}
        </div>
      )}

      <div className="flex items-center justify-between">
        <a
          href="https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42"
          target="_blank"
          rel="noreferrer"
          className="text-sm text-primary hover:underline flex items-center"
        >
          <span className="mr-2">📖</span> How to craft an effective brief
        </a>
        <Button
          variant={justSaved ? "secondary" : "primary"}
          type="submit"
          disabled={saving || questionAnswers.length === 0}
          className="min-w-[100px]"
        >
          {saving ? "Saving..." : justSaved ? "✓ Saved!" : "Confirm Brief"}
        </Button>
      </div>
    </form>
  );
};

type QuestionAnswerEditorProps = {
  agent: Agent;
};

export const ContextComponent = ({ agent }: QuestionAnswerEditorProps) => {
  return (
    <div className="max-h-[calc(100%-32px)] overflow-y-auto">
      <ContextEditor agent={agent} />
    </div>
  );
};
