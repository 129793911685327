import { Agent, GenericObjectToolUserInput, Tool } from "@/utils/types";
import React from "react";
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiCheckFill,
  RiCloseLine,
} from "react-icons/ri";
import SourceSetsInputPartialView from "./SourceSetsInputPartialView";

import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import { Heading } from "@/components/design-system/heading";
import MarkdownRenderer from "@/components/styled/MarkdownRenderer";
import MainPanel from "@/components/styled/panels/MainPanel";
import Tooltip from "@/components/styled/Tooltip";
import GenericObjectInputPartialView from "./GenericObjectInputPartialView";
import GoogleAdsAuthPartialView from "./GoogleAdsAuthPartialView";
import SpreadsheetInputPartialView from "./SpreadSheetInputPartialView";
import TextDocumentInputPartialView from "./TextDocumentInputPartialView";

type ToolsMainPanelProps = {
  agent: Agent;
  onAgentRefresh: () => Promise<void>;
  forcedTool?: string;
  onForcedToolDismiss?: () => void;
  isLoading: boolean;
  currentTool: Tool | undefined;
  currentToolIndex: number;
  isToolSetupDialogOpen: boolean;
  closeToolSetupDialog: () => void;
  allToolsRequiringUserInputs: Tool[];
  toolsThatNeedSetup: Tool[];
  handleConfirmTools: () => void;
  navigateTools: (direction: "next" | "prev") => void;
};
const ToolsMainPanel = ({
  agent,
  onAgentRefresh,
  isLoading,
  currentTool,
  currentToolIndex,
  isToolSetupDialogOpen,
  closeToolSetupDialog,
  allToolsRequiringUserInputs,
  toolsThatNeedSetup,
  handleConfirmTools,
  navigateTools,
}: ToolsMainPanelProps) => {
  return (
    <MainPanel
      open={!isLoading && isToolSetupDialogOpen}
      onClose={closeToolSetupDialog}
    >
      {currentTool && (
        <div className="flex flex-col gap-4 p-4">
          <div className="flex justify-between">
            <Heading>
              Actions setup - {currentTool ? currentTool.name : ""}
            </Heading>
            <Button
              onClick={closeToolSetupDialog}
              className="ml-auto"
              variant="plain"
            >
              <RiCloseLine />
            </Button>
          </div>
          <small>
            Not sure how to use this Action?{" "}
            <a
              href="https://tailortask.notion.site/Actions-149f7ba8885280a296d3c56fdbbae8ca?pvs=4"
              target="_blank"
              rel="noreferrer"
              className="underline hover:cursor-pointer"
            >
              Check our documentation.
            </a>
          </small>
          <Divider />
          <div className="list-disc list-inside text-xs dark:text-gray-100 flex flex-col gap-4">
            {agent.metadata.template_instructions &&
              Object.keys(agent.metadata.template_instructions).includes(
                currentTool.key,
              ) && (
                <>
                  {/* <Divider className="my-4" /> */}
                  <MarkdownRenderer
                    text={agent.metadata.template_instructions[currentTool.key]}
                  />
                </>
              )}
            <MarkdownRenderer text={currentTool.public_instructions} />
            {Object.entries(currentTool.user_inputs).map(
              ([inputName, inputSchema]) => (
                <React.Fragment key={inputName}>
                  {inputName !== "genericobject" && (
                    <>
                      <p>
                        {(inputSchema as { description?: string }).description}
                        {(inputSchema as { required?: string }).required && (
                          <span className="text-red-500 ml-1">*</span>
                        )}
                      </p>
                      {inputName === "sourceset" && (
                        <SourceSetsInputPartialView
                          key={currentTool.key}
                          agent={agent}
                          tool_key={currentTool.key}
                          onRefresh={onAgentRefresh}
                        />
                      )}
                      {inputName === "spreadsheet" && (
                        <SpreadsheetInputPartialView
                          key={currentTool.key}
                          agent={agent}
                          tool_key={currentTool.key}
                          onRefresh={onAgentRefresh}
                        />
                      )}
                      {inputName === "textdocument" && (
                        <TextDocumentInputPartialView
                          key={currentTool.key}
                          agent={agent}
                          tool_key={currentTool.key}
                          onRefresh={onAgentRefresh}
                        />
                      )}
                    </>
                  )}
                  {inputName === "genericobject" && (
                    <GenericObjectInputPartialView
                      key={currentTool.key}
                      agent={agent}
                      tool_key={currentTool.key}
                      schema={inputSchema as GenericObjectToolUserInput}
                      onRefresh={onAgentRefresh}
                    />
                  )}
                </React.Fragment>
              ),
            )}
            {currentTool.auth_token && (
              <>
                {currentTool.auth_token.key === "google_ads" && (
                  <GoogleAdsAuthPartialView
                    agentUUID={agent.uuid}
                    toolKey={currentTool.key}
                  />
                )}
              </>
            )}
          </div>
          <Divider className="mt-4" />
          <div className="flex justify-between mt-4 gap-4 items-center">
            {currentToolIndex > 0 && (
              <Button variant="secondary" onClick={() => navigateTools("prev")}>
                <RiArrowLeftLine /> Previous
              </Button>
            )}
            {allToolsRequiringUserInputs.length > 1 && (
              <div className="w-full">
                <Tooltip
                  content={`There are ${toolsThatNeedSetup.length} actions that need setup`}
                >
                  <p className="text-xs mt-2 text-gray-500 text-center">
                    {currentToolIndex + 1} /{" "}
                    {allToolsRequiringUserInputs.length}
                  </p>
                </Tooltip>
              </div>
            )}
            {currentToolIndex < allToolsRequiringUserInputs.length - 1 ? (
              <Button
                variant="secondary"
                onClick={() => navigateTools("next")}
                className="ml-auto"
                disabled={!currentTool.inputs_are_valid}
              >
                Next <RiArrowRightLine />
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  handleConfirmTools();
                  closeToolSetupDialog();
                }}
                className="ml-auto"
                disabled={!currentTool.inputs_are_valid}
              >
                <RiCheckFill />
                Confirm Actions
              </Button>
            )}
          </div>
        </div>
      )}
    </MainPanel>
  );
};

export default ToolsMainPanel;
