import BaseApi from "./base";
interface AdsStatus {
  connected: boolean;
  scopes: string[];
  token: string;
  clientId: string;
  appId: string;
}
export default class GoogleAdsApi extends BaseApi {
  static async googleAdsAuth(code: string): Promise<{
    token: string;
  }> {
    try {
      const response = await this.postApiCall<{
        token: string;
      }>("/dj-rest-auth/google/ads/", {
        code: code,
      });
      if ("token" in response) {
        this.setToken(response.token);
      }
      return response;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
  static async adsAuthStatus(): Promise<AdsStatus> {
    return this.authenticatedGetApiCall("/ads/status/");
  }
}
