import { Loading } from "@/components/Loading";
import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import { redirect } from "react-router-dom";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>TailorTask - Automate your tasks</title>
        <meta
          name="description"
          content="Repetitive tasks, automated. TailorTask uses A.I. to automate your most boring tasks."
        />
        <meta
          property="og:title"
          content="TailorTask - Repetitive tasks, automated"
        />
        <meta
          property="og:description"
          content="Repetitive tasks, automated. TailorTask uses A.I. to automate your most boring tasks."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://app.tailortask.ai/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
      </Helmet>
      <Loading />
    </>
  );
}

export async function loader({ params }: { params: any }) {
  const user = await MarconipyApi.user.me();
  if (user && !user?.onboarded) {
    return redirect("/agents/new?welcome=true");
  } else {
    const loggedIn = MarconipyApi.user.isAuthenticated();
    if (!loggedIn) {
      console.log("User is not logged in");
      return redirect("/login");
    }
    const agentUUID = params.agentUUID;
    const conversationUUID = params.chatUUID;
    if (conversationUUID && agentUUID) {
      return redirect(`/agents/${agentUUID}/chats/${conversationUUID}`);
    }
    if (agentUUID) {
      console.log("redirecting");
      return redirect(`/agents/${agentUUID}`);
    }
    const teamObj = await MarconipyApi.team.default();
    let agents = await MarconipyApi.agent.getAllByTeam(teamObj.uuid);
    agents = agents.sort((a, b) => {
      return (
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    });
    if (agents.length > 0) {
      if (
        location.pathname.includes("workflows") ||
        location.pathname == "/" ||
        location.pathname == undefined ||
        location.pathname == "/agents"
      ) {
        return redirect(`/agents/${agents[0].uuid}`);
      }
    }
    return redirect("/agents/new");
  }
}
