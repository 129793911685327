import { Link } from 'react-router-dom';
import Logo from './styled/Logo';

// Helper components
function SitemapHeading({ children }: { children: React.ReactNode }) {
  return <h3 className="text-sm font-medium text-gray-500">{children}</h3>
}

function SitemapLinks({ children }: { children: React.ReactNode }) {
  return <ul className="mt-6 space-y-4 text-sm">{children}</ul>
}

function SitemapLink(props: React.ComponentPropsWithoutRef<typeof Link>) {
  const isRelativeLink = props.to?.toString().startsWith('/') || 
                        props.to?.toString().includes('.tailortask.ai');
  
  return (
    <li>
      <Link
        {...props}
        target={isRelativeLink ? undefined : '_blank'}
        rel={isRelativeLink ? undefined : 'nofollow noindex'}
        className="font-medium text-gray-900 dark:text-gray-100 hover:text-gray-600 dark:hover:text-gray-400"
      />
    </li>
  )
}

// Social Icons
function SocialIconX(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 16 16" fill="currentColor" {...props}>
      <path d="M12.6 0h2.454l-5.36 6.778L16 16h-4.937l-3.867-5.594L2.771 16H.316l5.733-7.25L0 0h5.063l3.495 5.114L12.6 0zm-.86 14.376h1.36L4.323 1.539H2.865l8.875 12.837z" />
    </svg>
  )
}

function SocialIconYouTube(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 49 49" fill="currentColor" {...props}>
      <path d="M39.256,6.5H9.744C4.371,6.5,0,10.885,0,16.274v16.451c0,5.39,4.371,9.774,9.744,9.774h29.512c5.373,0,9.744-4.385,9.744-9.774V16.274C49,10.885,44.629,6.5,39.256,6.5z M47,32.726c0,4.287-3.474,7.774-7.744,7.774H9.744C5.474,40.5,2,37.012,2,32.726V16.274C2,11.988,5.474,8.5,9.744,8.5h29.512c4.27,0,7.744,3.488,7.744,7.774V32.726z" />
      <path d="M33.36,24.138l-13.855-8.115c-0.308-0.18-0.691-0.183-1.002-0.005S18,16.527,18,16.886v16.229c0,0.358,0.192,0.69,0.502,0.868c0.154,0.088,0.326,0.132,0.498,0.132c0.175,0,0.349-0.046,0.505-0.137l13.855-8.113c0.306-0.179,0.495-0.508,0.495-0.863S33.667,24.317,33.36,24.138z M20,31.37V18.63l10.876,6.371L20,31.37z" />
    </svg>
  )
}

export function PublicFooter() {
  return (
    <footer className="bg-white dark:bg-gray-900 p-4">
        <div className="grid grid-cols-2 gap-y-10 pb-6 lg:grid-cols-6 lg:gap-8">
          <div className="col-span-2 flex pt-6">
            <Logo variant="full" className="h-8 cursor-pointer dark:brightness-0 dark:invert hover:opacity-80" />
          </div>
          <div className="col-span-2 grid grid-cols-2 gap-x-8 gap-y-12 lg:col-span-4 lg:grid-cols-4 pt-6">
            {/* Company Links */}
            <div>
              <SitemapHeading>The company</SitemapHeading>
              <SitemapLinks>
                <SitemapLink to="https://www.tailortask.ai/product">Product</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/pricing">Pricing</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/contact-us">Contact us</SitemapLink>
              </SitemapLinks>
            </div>

            {/* Resources Links */}
            <div>
              <SitemapHeading>Solutions</SitemapHeading>
              <SitemapLinks>
                <SitemapLink to="https://www.tailortask.ai/solutions/for-marketers">For marketers</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/solutions/for-seo">For SEO</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/solutions/for-financial-analysts">For financial analysts</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/use-cases/internal-linking-tool">Internal Linking Tool</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/use-cases/competitive-monitoring-ai">Competitive Monitoring with AI</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/use-cases/ai-analyst">AI analyst</SitemapLink>
              </SitemapLinks>
            </div>
            <div>
              <SitemapHeading>Templates</SitemapHeading>
              <SitemapLinks>
                <SitemapLink to="https://www.tailortask.ai/templates">Templates</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/templates/seo">SEO Templates</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/templates/marketing">Marketing Templates</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/templates/productivity">Productivity Templates</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/templates/sales">Sales Templates</SitemapLink>
              </SitemapLinks>
            </div>
            <div>
              <SitemapHeading>Resources</SitemapHeading>
              <SitemapLinks>
                <SitemapLink to="https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=73" target="_blank" rel="nofollow noindex">Documentation</SitemapLink>
                <SitemapLink to="https://blog.tailortask.ai">Blog</SitemapLink>
                <SitemapLink to="https://blog.tailortask.ai/category/webinars/">Webinars</SitemapLink>
                <SitemapLink to="https://www.youtube.com/@tailortask" rel="nofollow noindex">Video Tutorials</SitemapLink>
              </SitemapLinks>
            </div>

            {/* Legal Links */}
            <div>
              <SitemapHeading>Legal</SitemapHeading>
              <SitemapLinks>
                <SitemapLink to="https://www.tailortask.ai/terms-of-use">Terms of Use</SitemapLink>
                <SitemapLink to="https://www.tailortask.ai/privacy-policy">Privacy Policy</SitemapLink>
              </SitemapLinks>
            </div>
          </div>
        </div>
        
        {/* Footer Bottom */}
        <div className="flex justify-between py-3">
          <div className="text-sm text-gray-900">
            <span>🇪🇺 Made in Europe with love.</span>
          </div>
          <div className="flex items-center gap-8">
            <Link
              to="https://bsky.app/profile/tailortask.bsky.social"
              target="_blank"
              aria-label="BlueSky"
              rel="nofollow noindex"
              className="text-gray-900 hover:text-gray-600"
            >
              <img src="https://www.tailortask.ai/logo-cluster/bluesky.svg" className="w-4 h-4" alt="BlueSky Logo" />
            </Link>
            <Link
              to="https://twitter.com/tailortask"
              target="_blank"
              aria-label="Twitter"
              rel="nofollow noindex"
              className="text-gray-900 hover:text-gray-600"
            >
              <SocialIconX className="w-4 h-4" />
            </Link>
            <Link
              to="https://www.youtube.com/@tailortask"
              target="_blank"
              aria-label="YouTube"
              rel="nofollow noindex"
              className="text-gray-900 hover:text-gray-600"
            >
              <SocialIconYouTube className="w-4 h-4" />
            </Link>
          </div>
        </div>
    </footer>
  )
}