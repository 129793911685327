import SignInWithGoogle from "@/components/SignInWithGoogle";
import MarconipyApi from "@/utils/marconipyApi";
import { useEffect, useState } from "react";
import { RiLoader2Line } from "react-icons/ri";

const GoogleAdsAuthPartialView = ({
  agentUUID,
  toolKey,
}: {
  agentUUID: string;
  toolKey: string;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [connected, setConnected] = useState<boolean>(false);

  useEffect(() => {
    async function checkConnectionStatus() {
      const status = (await MarconipyApi.googleAds.adsAuthStatus()) as any;
      setConnected(status.connected);
      setIsLoading(false);
    }
    checkConnectionStatus();
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "redirectUrl",
      window.location.href + "?save_input_token=" + toolKey,
    );
  }, [toolKey]);

  useEffect(() => {
    const addInput = async () => {
      await MarconipyApi.input.add({
        agentUUID,
        toolKey,
        type: "auth_token",
      });
    };
    if (connected) {
      addInput();
    }
  }, [agentUUID, connected, toolKey]);

  return (
    <div className="flex flex-col gap-2">
      {isLoading && (
        <div className="flex gap-2 items-center w-full">
          <RiLoader2Line className="animate-spin" />
          <p>Loading...</p>
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-row gap-1">
          {!connected ? (
            <div className="flex flex-col gap-2">
              <p className="pl-3">Connect to Google AdWords</p>
              <SignInWithGoogle provider={"google/ads"} />
            </div>
          ) : (
            <p>Connected to Google AdWords!</p>
          )}
        </div>
      )}
    </div>
  );
};

export default GoogleAdsAuthPartialView;
