import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { RiLoader2Fill, RiSlackFill } from "react-icons/ri";

type ConnectSlackButtonProps = {
  hideIfAlreadyConnected?: boolean;
  redirect_to?: string;
  callbackWithConnectionStatus?: (connected: boolean) => void;
};

export default function ConnectSlackButton({
  hideIfAlreadyConnected = false,
  callbackWithConnectionStatus,
}: ConnectSlackButtonProps) {
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [authUrl, setAuthUrl] = useState("/error");

  useEffect(() => {
    const checkConnection = async () => {
      const response = await MarconipyApi.slack.getConnectionStatus();
      if (response.connected) {
        setConnected(true);
        setLoading(false);
      }
      if (callbackWithConnectionStatus) {
        callbackWithConnectionStatus(response.connected);
      }
      const response2 = await MarconipyApi.slack.getAuthUrl();
      if (response2.url) {
        setAuthUrl(response2.url);
      }
      setLoading(false);
    };
    checkConnection();
  }, [callbackWithConnectionStatus]);

  const clicked = async () => {
    if (loading) return;
    const redirect_url = window.location.pathname;
    Cookies.set("slack_redirect", redirect_url, { expires: 1 }); // Cookie expires in 1 day
    window.location.href = authUrl;
  };
  if (hideIfAlreadyConnected && connected) {
    return null;
  }

  return (
    <Button
      variant={connected ? "plain" : "secondary"}
      onClick={() => {
        clicked();
      }}
      disabled={loading}
    >
      {loading ? <RiLoader2Fill /> : <RiSlackFill />}
      {loading && <span>Loading</span>}
      {!loading && !connected && <span>Connect Slack</span>}
      {!loading && connected && <span>Slack connected</span>}
    </Button>
  );
}
