import { Button } from "@/components/design-system/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "@/components/design-system/dialog";
import { Field, Fieldset, Label } from "@/components/design-system/fieldset";
import { Input } from "@/components/design-system/input";
import { Select } from "@/components/design-system/select";
import { Textarea } from "@/components/design-system/textarea";
import SignInWithGoogle from "@/components/SignInWithGoogle";
import Tooltip from "@/components/styled/Tooltip";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, RequiredInput, Spreadsheet } from "@/utils/types";
import { useEffect, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import { RiAddLine, RiCloseLine, RiLoader2Line } from "react-icons/ri";
const OutputFieldset = ({
  output,
  headers,
  index,
  onUpdate,
  onDelete,
}: {
  output: { key: string; description: string; column: number };
  headers: string[];
  index: number;
  onUpdate: (index: number, field: string, value: string | number) => void;
  onDelete: (index: number) => void;
}) => (
  <Fieldset className="flex flex-col gap-2 p-4 border rounded-lg">
    <div className="flex justify-between items-center">
      <h4 className="font-medium">Output {index + 1}</h4>
      <Tooltip content="Delete this output">
        <Button variant="plain" onClick={() => onDelete(index)}>
          <RiCloseLine className="text-red-500 hover:text-red-700" />
        </Button>
      </Tooltip>
    </div>
    <Field>
      <Label>Output key</Label>
      <Input
        value={output.key}
        onChange={(e) => onUpdate(index, "key", e.target.value)}
      />
    </Field>
    <Field>
      <Label>Output description</Label>
      <Textarea
        value={output.description}
        onChange={(e) => onUpdate(index, "description", e.target.value)}
      />
    </Field>
    <Field>
      <Label>Output column</Label>
      <Select
        value={output.column}
        onChange={(e) => onUpdate(index, "column", parseInt(e.target.value))}
      >
        {headers.map((header, i) => (
          <option key={i} value={i}>
            {header}
          </option>
        ))}
      </Select>
    </Field>
  </Fieldset>
);

const SpreadsheetSelector = ({
  connected,
  selectedSpreadsheet,
  onSelect,
  onRemove,
}: {
  connected: boolean;
  selectedSpreadsheet: { name: string } | null;
  onSelect: () => void;
  onRemove: () => void;
}) => {
  return (
    <Field className="flex flex-col gap-2">
      <Label>Input Sheet</Label>
      <div className="flex flex-row gap-1">
        {!connected ? (
          <SignInWithGoogle provider={"google/drive"} />
        ) : (
          <>
            <Button
              onClick={onSelect}
              variant={selectedSpreadsheet ? "secondary" : "primary"}
            >
              {selectedSpreadsheet
                ? selectedSpreadsheet.name + " (Click to change)"
                : "Select Spreadsheet"}
            </Button>
            {selectedSpreadsheet && (
              <Button variant="plain" onClick={onRemove}>
                <RiCloseLine />
              </Button>
            )}
          </>
        )}
      </div>
    </Field>
  );
};

const LoadingState = () => (
  <div className="flex gap-2 items-center justify-center p-4">
    <RiLoader2Line className="animate-spin text-primary" />
    <p className="text-gray-600">Loading spreadsheet data...</p>
  </div>
);

const BatchSummary = ({
  rowCount,
  selectedHeaders,
  outputs,
}: {
  rowCount: number;
  selectedHeaders: Record<string, any>;
  outputs: any[];
}) => (
  <div className="bg-blue-50 rounded-lg">
    <h4 className="font-medium mb-2">Batch Run Summary</h4>
    <ul className="space-y-2 text-sm">
      <li>• Total rows to process: {rowCount}</li>
      <li>• Input columns: {Object.keys(selectedHeaders).join(", ")}</li>
      <li>
        • Output columns configured:{" "}
        {outputs.map((output) => output.key).join(", ")}
      </li>
    </ul>
  </div>
);

const HeaderSelector = ({
  input,
  value,
  onChange,
  headers,
}: {
  input: RequiredInput;
  value: number;
  onChange: (key: string, value: number) => void;
  headers: string[];
}) => {
  return (
    <Field key={input.key}>
      <Label>
        {input.key} ({input.description})
      </Label>
      <Select
        id={`agent-required-input-${input.key}`}
        defaultValue={undefined}
        value={value}
        onChange={(e) => onChange(input.key, parseInt(e.target.value))}
      >
        {headers.map((header, index) => (
          <option key={index} value={index}>
            {header}
          </option>
        ))}
      </Select>
    </Field>
  );
};

const RunBatchPartialView = ({
  agent,
  conversationUUID,
  isOpen,
  setIsOpen,
}: {
  agent: Agent;
  conversationUUID: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [connected, setConnected] = useState<boolean>(false);
  const [selectedSpreadsheet, setSelectedSpreadsheet] =
    useState<Spreadsheet | null>(null);
  const [headers, setHeaders] = useState<string[]>([]);
  const [selectedHeaders, setSelectedHeaders] = useState<
    Record<string, number>
  >({});
  const [selectedOutputs, setSelectedOutputs] = useState<
    { key: string; description: string; column: number }[]
  >([]);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const [openPicker, _] = useDrivePicker();

  const addSelectedSpreadSheet = async (spreadsheet: {
    id: string;
    name: string;
  }) => {
    if (selectedSpreadsheet) {
      await MarconipyApi.googleDrive.deleteSheet(selectedSpreadsheet.uuid);
    }
    const api_spreadsheet = await MarconipyApi.googleDrive.addSheet(
      spreadsheet.id,
      spreadsheet.name,
    );
    setSelectedSpreadsheet(api_spreadsheet);
    setHeaders(api_spreadsheet.metadata.headers ?? []);
    let selectedHeaders: Record<string, number> = {};
    agent.required_inputs.forEach((input) => {
      selectedHeaders[input.key] = 0;
    });
    setSelectedHeaders(selectedHeaders);
  };

  const removeSelectedSpreadSheet = async () => {
    if (selectedSpreadsheet) {
      await MarconipyApi.googleDrive.deleteSheet(selectedSpreadsheet.uuid);
      setHeaders([]);
      setSelectedSpreadsheet(null);
    }
  };
  const handleOpenPicker = async () => {
    const data = await MarconipyApi.googleDrive.sheetsAuthStatus();
    openPicker({
      clientId: data.clientId,
      developerKey: "", //https://github.com/Jose-cd/React-google-drive-picker/issues/47
      viewId: "SPREADSHEETS",
      viewMimeTypes: "application/vnd.google-apps.spreadsheet",
      token: data.token, // pass oauth token in case you already have one
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: false,
      multiselect: false,
      appId: data.appId,
      callbackFunction: async (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        if (data.action === "picked") {
          const file = data.docs[0]; // Get the first (and only) selected file
          await addSelectedSpreadSheet({ id: file.id, name: file.name });
        }
      },
    });
  };
  useEffect(() => {
    async function checkConnectionStatus() {
      const status = (await MarconipyApi.googleDrive.sheetsAuthStatus()) as any;
      setConnected(status.connected);
      setIsLoading(false);
    }
    checkConnectionStatus();
  }, []);

  useEffect(() => {
    localStorage.setItem("redirectUrl", window.location.href);
  }, []);

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="flex flex-col gap-4">
            <p>1. Select the input Google Sheet (xlsx are not supported):</p>
            <SpreadsheetSelector
              connected={connected}
              selectedSpreadsheet={selectedSpreadsheet}
              onSelect={handleOpenPicker}
              onRemove={removeSelectedSpreadSheet}
            />
          </div>
        );
      case 2:
        return (
          <div className="flex flex-col gap-4">
            <p>2. Select the columns to use for each input:</p>
            {agent.required_inputs.map((input) => (
              <HeaderSelector
                key={input.key}
                headers={headers}
                input={input}
                value={selectedHeaders[input.key]}
                onChange={(key: string, value: number) =>
                  setSelectedHeaders({
                    ...selectedHeaders,
                    [key]: value,
                  })
                }
              />
            ))}
          </div>
        );
      case 3:
        return (
          <div className="flex flex-col gap-4">
            <p>2. Set up the output(s):</p>
            {selectedOutputs.map((output, index) => (
              <OutputFieldset
                key={index}
                output={output}
                index={index}
                headers={headers}
                onUpdate={(index, field, value) => {
                  setSelectedOutputs(
                    selectedOutputs.map((output, i) => {
                      if (i === index) {
                        return {
                          ...output,
                          [field]: value,
                        };
                      }
                      return output;
                    }),
                  );
                }}
                onDelete={(index) => {
                  setSelectedOutputs(
                    selectedOutputs.filter((_, i) => i !== index),
                  );
                }}
              />
            ))}
            <div className="">
              <Button
                variant="secondary"
                onClick={() =>
                  setSelectedOutputs([
                    ...selectedOutputs,
                    {
                      key: "",
                      description: "",
                      column: 0,
                    },
                  ])
                }
              >
                <RiAddLine />
                Add output
              </Button>
            </div>
          </div>
        );
      case 4:
        return (
          <BatchSummary
            rowCount={selectedSpreadsheet?.metadata.row_count ?? 0}
            selectedHeaders={selectedHeaders}
            outputs={selectedOutputs}
          />
        );
    }
    return <></>;
  };

  const areAllSelectedOutputsValid = selectedOutputs.every((output) => {
    return output.key && output.description && output.column >= 0;
  });

  const isStepValid = (step: number) => {
    switch (step) {
      case 1:
        return selectedSpreadsheet !== null;
      case 2:
        return Object.values(selectedHeaders).every(
          (value) => value !== undefined,
        );
      case 3:
        return selectedOutputs.length > 0 && areAllSelectedOutputsValid;
      case 4:
        return true;
      default:
        return true;
    }
  };

  const startBatchRun = async () => {
    if (!selectedSpreadsheet) {
      return;
    }
    await MarconipyApi.input.add({
      agentUUID: agent.uuid,
      toolKey: "task_completed",
      type: "spreadsheet",
      uuid: selectedSpreadsheet.uuid,
    });
    await MarconipyApi.conversation.startBatchRun(
      conversationUUID,
      selectedSpreadsheet?.uuid,
      selectedHeaders,
      selectedOutputs,
    );
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="max-w-2xl"
    >
      <DialogTitle className="text-xl font-bold border-b pb-4">
        Run Agent in Batch
      </DialogTitle>
      <DialogBody className="space-y-6">
        <div className="bg-gray-50 p-4 rounded-lg">
          <p className="text-gray-700">
            Run this agent multiple times, reading data from a Google Sheet.
          </p>
        </div>
        {isLoading && <LoadingState />}
        {!isLoading && renderStepContent()}
        <DialogActions>
          {currentStep > 1 && (
            <Button
              variant="secondary"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Previous
            </Button>
          )}
          {currentStep < 4 ? (
            <Button
              onClick={() => setCurrentStep(currentStep + 1)}
              disabled={!isStepValid(currentStep)}
            >
              Next
            </Button>
          ) : (
            <Button
              onClick={startBatchRun}
              disabled={!areAllSelectedOutputsValid}
            >
              Start Batch Run
            </Button>
          )}
        </DialogActions>
      </DialogBody>
    </Dialog>
  );
};

export default RunBatchPartialView;
