import { Button } from "@/components/design-system/button";
import { Input } from "@/components/design-system/input";
import { Switch } from "@/components/design-system/switch";
import { Textarea } from "@/components/design-system/textarea";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, RequiredInput } from "@/utils/types";
import { useState } from "react";
import { RiAddBoxFill, RiDeleteBin2Fill } from "react-icons/ri";

type RequiredInputsEditorProps = {
  requiredInputs: RequiredInput[];
  onInputsChange: (inputs: RequiredInput[]) => void;
};

const RequiredInputsEditor = ({
  requiredInputs,
  onInputsChange,
}: RequiredInputsEditorProps) => {
  const [editingField, setEditingField] = useState<{
    id: string;
    field: string;
  } | null>(null);

  const startEditing = (id: string, field: string) => {
    setEditingField({ id, field });
  };

  const stopEditing = () => {
    setEditingField(null);
  };

  const addRequiredInput = () => {
    const newRequiredInput = {
      id: Date.now().toString(),
      key: "",
      description: "",
      optional: false,
    };
    onInputsChange([...requiredInputs, newRequiredInput]);
    setEditingField({ id: newRequiredInput.id, field: "key" });
  };

  const updateRequiredInput = (
    id: string,
    field: "key" | "description",
    value: string,
  ) => {
    onInputsChange(
      requiredInputs.map((input) =>
        input.id === id ? { ...input, [field]: value } : input,
      ),
    );
  };

  const removeRequiredInput = (id: string) => {
    onInputsChange(requiredInputs.filter((input) => input.id !== id));
  };

  const toggleOptionalInput = (id: string) => {
    onInputsChange(
      requiredInputs.map((input) =>
        input.id === id ? { ...input, optional: !input.optional } : input,
      ),
    );
  };

  const renderField = (
    input: RequiredInput,
    field: "key" | "description",
    placeholder: string,
  ) => {
    const isEditing =
      editingField?.id === input.id && editingField?.field === field;

    if (isEditing) {
      return field === "key" ? (
        <Input
          value={input[field]}
          onChange={(e) => updateRequiredInput(input.id, field, e.target.value)}
          placeholder={placeholder}
          autoFocus
          onBlur={stopEditing}
          onKeyDown={(e) => e.key === "Enter" && stopEditing()}
          className="font-mono text-sm"
        />
      ) : (
        <Textarea
          value={input[field]}
          onChange={(e) => updateRequiredInput(input.id, field, e.target.value)}
          placeholder={placeholder}
          autoFocus
          onBlur={stopEditing}
          className="w-full dark:text-white"
        />
      );
    }

    return (
      <div
        onClick={() => startEditing(input.id, field)}
        className="p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 cursor-text"
      >
        {input[field] || <span className="text-gray-400">{placeholder}</span>}
      </div>
    );
  };

  return (
    <div className="rounded-lg">
      {requiredInputs.map((input: RequiredInput, index: number) => (
        <div
          key={index}
          className="space-y-3 mb-6 border-b pb-4 last:border-b-0"
        >
          {renderField(
            input,
            "key",
            "Input key (e.g. 'email', 'company_name')",
          )}
          {renderField(input, "description", "Describe what this input is for")}
          <div className="flex justify-between gap-4 items-center">
            <div className="flex items-center gap-3 bg-white dark:bg-gray-700 px-3 py-1 rounded">
              <p className="text-sm">Required</p>
              <Switch
                onChange={() => toggleOptionalInput(input.id)}
                checked={!input.optional}
              />
            </div>
            <Button
              type="button"
              variant="plain"
              onClick={() => removeRequiredInput(input.id)}
              className="hover:text-red-500"
            >
              <RiDeleteBin2Fill />
            </Button>
          </div>
        </div>
      ))}
      <Button
        type="button"
        onClick={addRequiredInput}
        variant="secondary"
        className="w-full mt-4"
      >
        <RiAddBoxFill className="mr-2" /> Add New Input
      </Button>
    </div>
  );
};

const InputsPartialView = ({
  agent,
  conversationUUID,
}: {
  agent: Agent;
  conversationUUID: string;
}) => {
  const [requiredInputs, setRequiredInputs] = useState<RequiredInput[]>(
    agent.required_inputs ?? [],
  );
  const [justSaved, setJustSaved] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isValid =
      requiredInputs.length > 0
        ? requiredInputs.every((input) => input.key && input.description)
        : true;

    if (isValid) {
      setError(null);
      setSaving(true);

      try {
        await MarconipyApi.agent.update(
          agent.uuid,
          {
            required_inputs: requiredInputs,
            metadata: { inputs: true },
          },
          conversationUUID,
        );
        setSaving(false);
        setJustSaved(true);
        setTimeout(() => setJustSaved(false), 2000);
      } catch (e) {
        setError("Failed to update inputs");
      }
    } else {
      setError("Inputs must have a key and description");
    }
  };
  return (
    <div className="overflow-y-auto">
      <form onSubmit={handleSubmit} className="space-y-8">
        <RequiredInputsEditor
          requiredInputs={requiredInputs}
          onInputsChange={setRequiredInputs}
        />
        {error && (
          <div className="bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
            {error}
          </div>
        )}

        <div className="flex items-center justify-between">
          <a
            href="https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42"
            target="_blank"
            rel="noreferrer"
            className="text-sm text-primary hover:underline flex items-center"
          >
            <span className="mr-2">📖</span> How to add effective inputs
          </a>
          <Button
            variant={justSaved ? "secondary" : "primary"}
            type="submit"
            className="min-w-[100px]"
            disabled={saving}
          >
            {saving ? "Saving..." : justSaved ? "✓ Saved!" : "Confirm Inputs"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InputsPartialView;
