import MarconipyApi from "@/utils/marconipyApi";
import { AgentState, Message, WebSocketMessage } from "@/utils/types";
import WebsocketService from "@/utils/websocket";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

let globalWebSocket: WebsocketService | null = null;

const useDocumentTitle = (title: string) => {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = title;
    return () => {
      document.title = previousTitle;
    };
  }, [title]);
};

const useChat = ({
  pageTitle,
  conversationUUID,
  onAgentRefresh,
  onImportantMessage,
  onNewAgentState,
}: {
  pageTitle: string;
  conversationUUID?: string;
  onAgentRefresh: () => void;
  onImportantMessage: (message: WebSocketMessage) => void;
  onNewAgentState: (state: AgentState) => void;
}): {
  isConnected: boolean;
  messages: Message[];
  sendMessage: (message: string) => void;
  isLoading: boolean;
  loadMessages: ({
    force,
    beforeTimestamp,
  }: {
    force?: boolean;
    beforeTimestamp?: number;
  }) => Promise<void>;
  chatRef?: React.RefObject<HTMLDivElement>;
  runUUID: string | null;
} => {
  const websocketRef = useRef<any | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const location = useLocation();
  const [messages, setMessages] = useState<Message[]>([]);
  const [
    isLoadingMessagesForTheFirstTime,
    setIsLoadingMessagesForTheFirstTime,
  ] = useState(true);
  const messageQueue = useRef<Message[]>([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const chatRef = useRef<HTMLDivElement>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const notificationSound = new Audio("/notification.mp3");
  const documentTitle = `${unreadCount ? `(${unreadCount}) ` : ""}${pageTitle}`;
  const [runUUID, setRunUUID] = useState<string | null>(null);
  useDocumentTitle(documentTitle);

  const scrollToEndOfChat = useCallback(async () => {
    await new Promise((r) => setTimeout(r, 200));
    if (chatRef.current) {
      const scrollOptions: ScrollIntoViewOptions = {
        behavior: "smooth",
        block: "end",
      };
      chatRef.current?.scrollIntoView(scrollOptions);
    }
  }, []);
  const processMessageQueue = useCallback(() => {
    if (messageQueue.current.length > 0 && !isProcessingQueue) {
      setIsProcessingQueue(true);
      const message = messageQueue.current.shift();
      if (!message) {
        return;
      }
      setMessages((prev) => {
        if (prev.some((msg) => msg.uuid === message.uuid)) {
          return prev;
        }
        return [...prev, message];
      });
      setIsProcessingQueue(false);
      scrollToEndOfChat();
    }
  }, [isProcessingQueue, scrollToEndOfChat]);

  useEffect(() => {
    const handleConnection = () => {
      setIsConnected(true);
      websocketRef.current.refreshAgent();
    };

    const handleDisconnection = () => {
      setIsConnected(false);
    };

    const handleMessage = (data: WebSocketMessage) => {
      if (data.type === "chat_message") {
        messageQueue.current.push(data.message as Message);
        processMessageQueue();

        const toolUse = (data.message as Message).content.find(
          (content) => content.type === "tool_use",
        );
        if (
          toolUse &&
          (toolUse.name === "pause_task" ||
            toolUse.name === "task_completed") &&
          document.visibilityState === "hidden"
        ) {
          notificationSound.play();
        }
        if (document.visibilityState === "hidden") {
          setUnreadCount((prev) => prev + 1);
        }
      } else if (data.type === "agent_update" && onAgentRefresh) {
        onAgentRefresh();
      } else if (data.type === "conversation_state_update") {
        onNewAgentState(data.message as AgentState);
      } else if (data.type === "agent_name_update" && onImportantMessage) {
        onImportantMessage(data);
      } else if (data.type === "open_setting_step" && onImportantMessage) {
        onImportantMessage(data);
      }
    };

    if (!globalWebSocket && conversationUUID) {
      globalWebSocket = WebsocketService.getInstance();
      globalWebSocket.connect(conversationUUID);
    }

    websocketRef.current = globalWebSocket;

    if (websocketRef.current) {
      websocketRef.current.on("connect", handleConnection);
      websocketRef.current.on("disconnect", handleDisconnection);
      websocketRef.current.on("message", handleMessage);
    }

    return () => {
      if (websocketRef.current) {
        websocketRef.current.off("connect", handleConnection);
        websocketRef.current.off("disconnect", handleDisconnection);
        websocketRef.current.off("message", handleMessage);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationUUID]);

  useEffect(() => {
    return () => {
      if (globalWebSocket) {
        globalWebSocket.disconnect();
        globalWebSocket = null;
      }
    };
  }, [location]);

  const sendMessage = useCallback(
    async (messagecontent: string) => {
      if (messagecontent.trim() && isConnected && websocketRef.current) {
        websocketRef.current.send(messagecontent);
      } else {
        console.log("Message is empty or not connected");
      }
      scrollToEndOfChat();
    },
    [isConnected, scrollToEndOfChat],
  );
  const loadMessages = useCallback(
    async ({
      beforeTimestamp,
      force = false,
    }: {
      force?: boolean;
      beforeTimestamp?: number;
    }) => {
      if (
        isConnected &&
        conversationUUID &&
        ((isLoadingMessagesForTheFirstTime && messages.length == 0) ||
          force ||
          beforeTimestamp)
      ) {
        const newMessages = await MarconipyApi.conversation.getMessages(
          conversationUUID,
          {
            limit: 15,
            beforeTimestamp,
          },
        );
        if (newMessages.length == 0) {
          return;
        }
        if (force) {
          const messages = [...newMessages].reverse();
          setMessages(messages);
          scrollToEndOfChat();
        } else {
          // Only add unique messages based on UUID
          setMessages((prev) => {
            const uniqueMessages = newMessages.filter(
              (newMsg) =>
                !prev.some((existingMsg) => existingMsg.uuid === newMsg.uuid),
            );
            let reversedMessages = [...uniqueMessages].reverse();
            return beforeTimestamp
              ? [...reversedMessages, ...prev]
              : reversedMessages;
          });
          if (!beforeTimestamp) {
            scrollToEndOfChat();
          }
        }

        setIsLoadingMessagesForTheFirstTime(false);
      }
    },
    [
      isConnected,
      conversationUUID,
      isLoadingMessagesForTheFirstTime,
      messages.length,
      scrollToEndOfChat,
    ],
  );

  useEffect(() => {
    if (
      isConnected &&
      messages.length === 0 &&
      conversationUUID &&
      isLoadingMessagesForTheFirstTime
    ) {
      loadMessages({ force: false });
    }
  }, [
    conversationUUID,
    isConnected,
    isLoadingMessagesForTheFirstTime,
    loadMessages,
    messages.length,
    onAgentRefresh,
  ]);

  useEffect(() => {
    setMessages([]);
    setIsLoadingMessagesForTheFirstTime(true);
  }, [conversationUUID]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isConnected && messages.length === 0 && conversationUUID) {
      intervalId = setInterval(() => {
        const currentMessagesLength = messages.length;
        if (currentMessagesLength === 0) {
          loadMessages({ force: true });
        }
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isConnected, conversationUUID, messages.length, loadMessages]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setUnreadCount(0);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  useEffect(() => {
    if (messages.length == 0) {
      return;
    }
    const lastMessage = messages[messages.length - 1];
    setRunUUID(lastMessage.run ?? null);
  }, [messages]);

  return {
    isConnected,
    messages,
    sendMessage,
    isLoading: isLoadingMessagesForTheFirstTime,
    loadMessages,
    chatRef,
    runUUID,
  };
};

export default useChat;
