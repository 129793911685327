import BaseApi from "./base";

export default class SlackApi extends BaseApi {
  static async getConnectionStatus(): Promise<{
    connected: boolean;
    message: string;
  }> {
    return await this.authenticatedGetApiCall<{
      connected: boolean;
      message: string;
    }>(`/slack/status/`);
  }

  static async getAuthUrl(): Promise<{
    url: string;
  }> {
    return await this.authenticatedGetApiCall<{
      url: string;
    }>(`/slack/install/`);
  }

  static async oauthCallback(
    code: any,
    state: any,
  ): Promise<{
    message: string;
  }> {
    return await this.postApiCall<{
      message: string;
    }>(`/slack/oauth/`, {
      code: code,
      state: state,
    });
  }
}
