import MarconipyApi from "@/utils/marconipyApi";
import { Agent, AgentTemplate, Conversation } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "./AuthenticationContext";
import { useTeams } from "./TeamContext";

interface AgentConversationsMap {
  agent: Agent;
  conversations: Conversation[];
}

interface AgentContextType {
  loading: boolean;
  conversationsByAgent: Conversation[];
  agent: Agent | null;
  agents: Agent[];
  currentConversation: Conversation | null;
  selectConversation: (conversationUUID: string) => void;
  newConversation: (agent: Agent) => Promise<void>;
  createNewAgent: () => Promise<void>;
  deleteAgent: (agent: Agent) => Promise<void>;
  restoreDeletedAgent: (agent: Agent) => Promise<void>;
  updateCurrentAgent: (agent: Agent, params: any) => Promise<void>;
  reloadCurrentAgent: () => Promise<void>;
  cloneAgent(from_agent: Agent): Promise<void>;
  cloneAgentFromTemplate: (template: AgentTemplate) => Promise<void>;
  agentWasRefreshed: boolean;
  reloadConversations: () => Promise<void>;
  reset: () => void;
}

const AgentsContext = createContext<AgentContextType | null>(null);

export const useAgents = () => {
  const context = useContext(AgentsContext);
  if (!context) {
    throw new Error("useAgents must be used within a TeamProvider");
  }
  return context;
};

export const AgentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { selectedTeam, teamHasChanged, switchToTeamUUID } = useTeams();
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [mapConversationByAgents, setMapConversationsByAgent] = useState<
    AgentConversationsMap[]
  >([]);
  const [currentConversation, setCurrentConversation] =
    useState<Conversation | null>(null);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [agentWasRefreshed, setAgentWasRefreshed] = useState(false);
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();

  const handleSelectConversation = useCallback(
    (conversationUUID: string) => {
      const conversation = conversations.find(
        (c) => c.uuid === conversationUUID,
      );
      if (!conversation) {
        return;
      }
      const agent = agents.find((a) => a.uuid === conversation.agent);
      if (agent) {
        setSelectedAgent(agent);
        setCurrentConversation(conversation);
        navigate("/agents/" + agent.uuid + "/chats/" + conversation.uuid);
      }
    },
    [agents, conversations, navigate],
  );

  const loadAgent = useCallback(async (agentUUID: string) => {
    const agent = await MarconipyApi.agent.get(agentUUID);
    setAgents((prev) => [...prev, agent]);
    setSelectedAgent(agent);
  }, []);
  const loadAgentAndConversation = useCallback(
    async (agentUUID: string, conversationUUID: string) => {
      const agent = await MarconipyApi.agent.get(agentUUID);
      const conversation =
        await MarconipyApi.conversation.get(conversationUUID);
      setMapConversationsByAgent((prev) => [
        ...prev,
        {
          agent: agent,
          conversations: [conversation],
        },
      ]);
      setConversations((prev) => [...prev, conversation]);
      setAgents((prev) => [...prev, agent]);
      setSelectedAgent(agent);
      setCurrentConversation(conversation);
    },
    [],
  );
  useEffect(() => {
    if (!location.pathname.includes("/agents/")) {
      return;
    }

    const splitLocation = location.pathname.split("?")[0].split("/");
    const agentUUID = splitLocation[2];

    if (agentUUID === "new") {
      setCurrentConversation(null);
      setSelectedAgent(null);
      return;
    }

    if (location.pathname.includes("/chats")) {
      const conversationUUID = splitLocation[4];
      const agent = agents.find((w) => w.uuid === agentUUID);
      if (!conversationUUID || conversationUUID === "") {
        if (agent) {
          setSelectedAgent(agent);
        } else {
          loadAgent(agentUUID);
        }
        return;
      }
      const conversation = conversations.find(
        (w) => w.uuid === conversationUUID,
      );

      if (!conversation) {
        loadAgentAndConversation(agentUUID, conversationUUID);
        return;
      }

      setCurrentConversation(conversation);

      if (!agent) {
        loadAgent(agentUUID);
        return;
      }

      if (agentUUID !== agent.uuid) {
        navigate(`/agents/${agent.uuid}/chats/${conversation.uuid}`);
        return;
      }

      setSelectedAgent(agent);
      return;
    }

    const agent = agents.find((w) => w.uuid === agentUUID);
    const conversation = conversations.find((w) => w.agent === agentUUID);
    if (!agent) {
      navigate("/agents/new");
      return;
    }

    setSelectedAgent(agent);

    if (!conversation) {
      navigate("/agents/new");
      return;
    }

    setCurrentConversation(conversation);
    if (
      location.pathname != `/agents/${agentUUID}/chats/${conversation.uuid}`
    ) {
      navigate(`/agents/${agentUUID}/chats/${conversation.uuid}`);
      return;
    }
  }, [
    location,
    agents,
    conversations,
    navigate,
    currentConversation,
    loadAgent,
    loadAgentAndConversation,
  ]);
  const loadAgents = useCallback(async () => {
    if (!selectedTeam) {
      return;
    }
    const agents = await MarconipyApi.agent.getAllByTeam(selectedTeam?.uuid);
    let fetchedAgents = agents;
    fetchedAgents = fetchedAgents.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    );
    setAgents(fetchedAgents);
    setLoading(false);
  }, [selectedTeam]);

  const loadConversations = useCallback(async () => {
    if (!selectedTeam) {
      return;
    }
    const conversations = await MarconipyApi.conversation.getAll();
    let fetchedConversations = conversations;
    fetchedConversations = fetchedConversations.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    );
    setConversations(fetchedConversations);
    setLoading(false);
  }, [selectedTeam]);

  const deleteAgent = useCallback(
    async (agent: Agent) => {
      posthog.capture("agentEdit: delete agent");
      await MarconipyApi.agent.delete(agent.uuid);
      setAgents(agents.filter((w) => agent.uuid != w.uuid));
      setConversations((prev) => prev.filter((w) => w.agent != agent.uuid));
      setMapConversationsByAgent((prev) =>
        prev.filter((w) => w.agent.uuid != agent.uuid),
      );
      navigate("/agents");
    },
    [navigate, posthog, agents],
  );

  const restoreDeletedAgent = useCallback(
    async (agent: Agent) => {
      if (!currentConversation) {
        return;
      }
      posthog.capture("agentEdit: restore deleted agent");
      const restoreAgent = await MarconipyApi.agent.update(
        agent.uuid,
        {
          deleted: false,
        },
        currentConversation.uuid,
      );
      setAgents([restoreAgent, ...agents]);
    },
    [currentConversation, posthog, agents],
  );

  const createNewAgent = useCallback(async () => {
    posthog.capture("agentEdit: create agent");
    const response = await MarconipyApi.agent.create();
    setAgents([response.agent, ...agents]);
    setConversations((prev) => [response.conversation, ...prev]);
    setMapConversationsByAgent((prev) => [
      ...prev,
      {
        agent: response.agent,
        conversations: [response.conversation],
      },
    ]);
    navigate(
      "/agents/" + response.agent.uuid + "/chats/" + response.conversation.uuid,
    );
  }, [posthog, agents, navigate]);

  const reloadCurrentAgent = useCallback(async () => {
    if (!selectedAgent) {
      return;
    }
    posthog.capture("agentEdit: reload agent");
    const agent = await MarconipyApi.agent.get(selectedAgent?.uuid);
    setSelectedAgent(agent);
    setAgentWasRefreshed(true);
  }, [posthog, selectedAgent]);

  const updateCurrentAgent = useCallback(
    async (agent: Agent, params: any) => {
      if (!currentConversation) {
        return;
      }
      posthog.capture("agentEdit: update agent");
      setAgentWasRefreshed(true);
      const newAgent = await MarconipyApi.agent.update(
        agent.uuid,
        params,
        currentConversation.uuid,
      );
      setSelectedAgent(newAgent);
      setAgents(
        agents.map((w) => {
          if (w.uuid === agent.uuid) {
            return newAgent;
          }
          return w;
        }),
      );
    },
    [posthog, currentConversation, agents],
  );

  const cloneAgentFromTemplate = useCallback(
    async (template: AgentTemplate) => {
      posthog.capture("agentEdit: clone agent from template");
      try {
        const response = await MarconipyApi.agent.clone(
          template.agent.uuid,
          template.metadata,
        );
        setAgents([response.agent, ...agents]);
        setConversations((prev) => [response.conversation, ...prev]);
        setMapConversationsByAgent((prev) => [
          ...prev,
          {
            agent: response.agent,
            conversations: [response.conversation],
          },
        ]);
        navigate(
          "/agents/" +
            response.agent.uuid +
            "/chats/" +
            response.conversation.uuid,
        );
      } catch (e) {
        console.error(e);
        toast.error(
          "There was an error creating your agent. Try again or reach out to info@tailortask.ai",
        );
      }
    },
    [posthog, agents, navigate],
  );

  const cloneAgent = useCallback(
    async (from_agent: Agent) => {
      posthog.capture("agentEdit: clone agent");
      try {
        const response = await MarconipyApi.agent.clone(from_agent.uuid, {});
        setAgents([response.agent, ...agents]);
        navigate(
          "/agents/" +
            response.agent.uuid +
            "/chats/" +
            response.conversation.uuid,
        );
      } catch (e) {
        console.error(e);
        toast.error(
          "There was an error cloning your agent. Try again or reach out to info@tailortask.ai",
        );
      }
    },
    [posthog, agents, navigate],
  );

  const reloadConversations = useCallback(async () => {
    if (!selectedTeam) {
      return;
    }
    const conversations = await MarconipyApi.conversation.getAll();
    let fetchedConversations = conversations;
    fetchedConversations = fetchedConversations.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    );
    setConversations(fetchedConversations);
    setLoading(false);
  }, [selectedTeam]);

  const newConversation = useCallback(
    async (agent: Agent) => {
      const newConversation = await MarconipyApi.conversation.create(
        agent.uuid,
      );
      await reloadConversations();
      navigate(`/agents/${agent.uuid}/chats/${newConversation.uuid}`);
    },
    [navigate, reloadConversations],
  );

  useEffect(() => {
    if (agentWasRefreshed) {
      setAgentWasRefreshed(false);
    }
  }, [agentWasRefreshed]);

  useEffect(() => {
    if (isAuth && selectedTeam) {
      if (agents.length === 0 || teamHasChanged) {
        loadAgents();
        loadConversations();
      }
    } else {
      setAgents([]);
      setSelectedAgent(null);
    }
  }, [
    isAuth,
    loadAgents,
    selectedTeam,
    teamHasChanged,
    agents.length,
    loadConversations,
  ]);

  useEffect(() => {
    if (
      selectedTeam &&
      selectedAgent &&
      selectedAgent.team != selectedTeam.uuid
    ) {
      switchToTeamUUID(selectedAgent.team);
    }
  });

  useEffect(() => {
    let conversationsByAgent: AgentConversationsMap[] = [];
    for (const agent of agents) {
      conversationsByAgent.push({
        agent,
        conversations: conversations.filter((c) => c.agent === agent.uuid),
      });
    }
    setMapConversationsByAgent(conversationsByAgent);
  }, [conversations, agents]);

  const reset = useCallback(() => {
    setSelectedAgent(null);
    setAgents([]);
    setConversations([]);
    setCurrentConversation(null);
    setMapConversationsByAgent([]);
  }, []);

  const contextValue: AgentContextType = {
    loading,
    conversationsByAgent:
      mapConversationByAgents.find((item) => item.agent == selectedAgent)
        ?.conversations ?? [],
    agent: selectedAgent,
    agents,
    currentConversation,
    selectConversation: handleSelectConversation,
    newConversation,
    createNewAgent,
    deleteAgent,
    restoreDeletedAgent,
    updateCurrentAgent,
    reloadCurrentAgent,
    cloneAgentFromTemplate,
    cloneAgent,
    agentWasRefreshed,
    reloadConversations,
    reset,
  };

  return (
    <AgentsContext.Provider value={contextValue}>
      {children}
    </AgentsContext.Provider>
  );
};
