import { Loading } from "@/components/Loading";
import MarconipyApi from "@/utils/marconipyApi";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLoaderData, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

type userDataResponse = {
  code: string;
};

export default function GoogleDriveAuth() {
  const navigate = useNavigate();
  const data = useLoaderData() as userDataResponse;
  const posthog = usePostHog();
  const [errors, setErrors] = useState<string | null>(null);

  useEffect(() => {
    const authCall = async (code?: string) => {
      try {
        if (code) {
          await MarconipyApi.googleAds.googleAdsAuth(code);
        }
        posthog?.capture("auth", { method: "google ads" });
        const redirectUrl = localStorage.getItem("redirectUrl");
        if (redirectUrl) {
          localStorage.removeItem("redirectUrl");
          window.location.href = redirectUrl.toString();
          return;
        } else {
          navigate("/");
          return;
        }
      } catch (error: any) {
        setErrors(error.response.data.detail);
        toast.error(error.response.data.detail);
      }
    };

    authCall(data.code);
  }, [data.code, navigate, posthog]);

  return (
    <>
      <Helmet>
        <title>Signing up - TailorTask</title>
        <meta
          name="description"
          content="Login to access your TailorTask account. Start automating your most boring tasks."
        />
        <meta property="og:title" content="Login - Tailor" />
        <meta
          property="og:description"
          content="Login to access your TailorTask account. Start automating your most boring tasks."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://app.tailortask.ai/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
      </Helmet>
      <div className="col-span-12 grid grid-cols-12">
        <Loading />
        {errors && (
          <p className="text-white dark:text-black text-lg">Error: {errors}</p>
        )}
      </div>
    </>
  );
}

export async function loader({
  params,
  request,
}: {
  params: any;
  request: Request;
}) {
  try {
    // const { search } = useLocation();
    const queryparams = new URLSearchParams(request.url.split("?")[1]);
    const code = queryparams.get("code") as string;
    return { code };
  } catch (e) {
    console.log(e);
    throw e;
  }
}
