import { Spreadsheet } from "../types";
import BaseApi from "./base";

interface SheetsStatus {
  connected: boolean;
  token: string;
  clientId: string;
  appId: string;
}

export default class GoogleDriveApi extends BaseApi {
  static async googleDriveAuth(code: string): Promise<{
    token: string;
  }> {
    try {
      const response = await this.postApiCall<{
        token: string;
      }>("/dj-rest-auth/google/drive/", {
        code: code,
      });
      if ("token" in response) {
        this.setToken(response.token);
      }
      return response;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  static async sheetsAuthStatus(): Promise<SheetsStatus> {
    return this.authenticatedGetApiCall("/spreadsheets/status/");
  }

  static async addSheet(
    spreadsheet_id: string,
    name: string,
  ): Promise<Spreadsheet> {
    return this.authenticatedPostApiCall<Spreadsheet>("/spreadsheets/", {
      spreadsheet_id: spreadsheet_id,
      name: name,
      provider: "google_sheets",
    });
  }

  static async deleteSheet(spreadsheet_id: string): Promise<void> {
    return this.authenticatedDeleteApiCall(`/spreadsheets/${spreadsheet_id}/`);
  }
}
