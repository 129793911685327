import { BackgroundJob, Conversation, Message, RequiredInput } from "../types";
import BaseApi from "./base";

export default class ConversationApi extends BaseApi {
  static async getAll(): Promise<Conversation[]> {
    return await this.authenticatedGetApiCall<Conversation[]>(
      `/conversations/`,
    );
  }

  static async get(conversationUUID: string): Promise<Conversation> {
    return await this.authenticatedGetApiCall<Conversation>(
      `/conversations/${conversationUUID}/`,
    );
  }

  static async create(agentUUID: string): Promise<Conversation> {
    return await this.authenticatedPostApiCall<Conversation>(
      `agents/${agentUUID}/conversations/`,
    );
  }

  static async sendFormInputs(
    conversationUUID: string,
    required_inputs: RequiredInput[],
  ): Promise<Conversation> {
    let processedRequiredInputs = [];
    let processedFilesRecord: Record<string, File> = {};
    for (const input of required_inputs) {
      if (input.value instanceof File) {
        processedRequiredInputs.push({
          ...input,
          value: "file",
        });
        processedFilesRecord[input.id] = input.value;
      } else {
        processedRequiredInputs.push(input);
      }
    }
    const formData = new FormData();
    formData.append("required_inputs", JSON.stringify(processedRequiredInputs));
    for (const [key, value] of Object.entries(processedFilesRecord)) {
      formData.append(key, value);
    }
    return await this.authenticatedFormPostApiCall<Conversation>(
      `/conversations/${conversationUUID}/form/`,
      formData,
    );
  }
  static async sendRunFormInputs(
    conversationUUID: string,
    required_inputs: RequiredInput[],
  ): Promise<Conversation> {
    let processedRequiredInputs = [];
    let processedFilesRecord: Record<string, File> = {};
    for (const input of required_inputs) {
      if (input.value instanceof File) {
        processedRequiredInputs.push({
          ...input,
          value: "file",
        });
        processedFilesRecord[input.id] = input.value;
      } else {
        processedRequiredInputs.push(input);
      }
    }
    const formData = new FormData();
    formData.append("required_inputs", JSON.stringify(processedRequiredInputs));
    for (const [key, value] of Object.entries(processedFilesRecord)) {
      formData.append(key, value);
    }
    return await this.authenticatedFormPostApiCall<Conversation>(
      `/conversations/${conversationUUID}/runs/form/`,
      formData,
    );
  }

  static async startBatchRun(
    conversationUUID: string,
    spreadsheetUUID: string,
    inputsMap: Record<string, number>,
    outputsMap: {
      key: string;
      description: string;
      column: number;
    }[],
  ) {
    return await this.authenticatedPostApiCall<BackgroundJob>(
      `/conversations/${conversationUUID}/runs/batch/`,
      {
        spreadsheet_uuid: spreadsheetUUID,
        inputs_map: inputsMap,
        outputs_map: outputsMap,
      },
    );
  }

  static async getMessages(
    conversationUUID: string,
    params: {
      limit?: number;
      beforeTimestamp?: number;
    },
  ): Promise<Message[]> {
    let url = `/conversations/${conversationUUID}/messages/`;
    if (params.limit) {
      url += `?limit=${params.limit}`;
    }
    if (params.beforeTimestamp) {
      if (url.includes("?")) {
        url += `&`;
      } else {
        url += `?`;
      }
      url += `before_timestamp=${params.beforeTimestamp}`;
    }
    return await this.authenticatedGetApiCall<Message[]>(url);
  }

  static async editMessage(
    conversationUUID: string,
    messageUUID: string,
    params: any,
  ): Promise<Message> {
    return await this.authenticatedPatchApiCall<Message>(
      `/conversations/${conversationUUID}/messages/${messageUUID}/`,
      params,
    );
  }

  static async getBackgroundJob(
    conversationUUID: string,
    toolUseID: string,
  ): Promise<BackgroundJob | null> {
    return await this.authenticatedGetApiCall<BackgroundJob | null>(
      `/conversations/${conversationUUID}/jobs/${toolUseID}/`,
    );
  }

  static async skipTool(
    conversationUUID: string,
    toolKey: string,
  ): Promise<void> {
    await this.authenticatedPostApiCall<void>(
      `/conversations/${conversationUUID}/tools/skip/`,
      {
        tool_key: toolKey,
      },
    );
  }

  static async skipInputs(conversationUUID: string): Promise<void> {
    await this.authenticatedPostApiCall<void>(
      `/conversations/${conversationUUID}/inputs/skip/`,
    );
  }
}
